"use client"
import React                                                from 'react'
import {Card, CardContent, Grid, ThemeProvider, Typography} from '@material-ui/core'
import theme                                                from '../theme'
import Layout                                               from '../components/shared/layout'
import {makeStyles}                                         from '@material-ui/core/styles'
import {graphql}                                            from 'gatsby'
import {BgImage}                                            from "gbimage-bridge";


export const query = graphql`
    query {
        sanitySiteSettings {
            siteMedia {
                homePageBackground {
                    asset {
                        gatsbyImageData
                    }
                }
            }
        }

    }

`
const useStyles = makeStyles((theme) => ({

    pageTitle: {
        fontWeight: 500,
        margin: '0.5rem 0 0.7rem',
        textAlign: 'center'
    },
    homeImage: {
        // width: '100%',
        // minHeight: '300px'
        height: '100vh'
    }
}))


const ContactPage = (props: any) => {
    const classes = useStyles(theme)
    return (
        <ThemeProvider theme={theme}>
            <Layout helmetData={{
                pageTitle: 'Contact',
                metaDescription: 'Level 22 Chambers is a leading administrative and commercial set in Sydney, Australia.',
                slug: 'contact'
            }} backgroundImage={''}>
                <BgImage className={classes.homeImage} fadeIn={true} durationFadeIn={1000}
                         backgroundColor={'#FFF'}
                         image={{
                             layout: 'fullWidth',
                             width: 2000,
                             height: 1063,
                             images: props.data.sanitySiteSettings.siteMedia.homePageBackground.asset.gatsbyImageData.images
                         }}>
                    <Grid className={classes.homeImage} container justify={'center'} alignItems={'flex-start'}>
                        <Grid style={{marginTop: '5rem'}} item xs={6}>
                            <Card>
                                <Typography className={classes.pageTitle} variant={'h1'} component={'h1'}>
                                    Contact Us
                                </Typography>
                                <CardContent>
                                    <Typography align={'center'} variant={'body1'} component={'p'}>
                                        LEVEL 22 CHAMBERS<br/>
                                        52 Martin Place<br/>
                                        Sydney NSW 2000<br/>
                                        Australia<br/>
                                        <em>Our DX service has been discontinued</em>
                                    </Typography>
                                    <Typography align={'center'} variant={'body1'} component={'p'}>
                                        <strong>T:</strong> 02 9151 2222<br/>
                                        <strong>E:</strong> clerk@level22.com.au
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </BgImage>
            </Layout>
        </ThemeProvider>
    )
}

export default ContactPage
